// *** HELPERS

// Check for reduced motion setting
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

// Check if touch device
const touchDevice = matchMedia('(hover: none)').matches;

// ***


// *** GENERAL

const main = document.getElementById('main');

// timer for header spin word animation
const headerAnimationTiming = 2500;

// ***


// *** HEADER SPIN WORD ANIMATION

// Initialize variables
const wordSpinner = document.querySelector(".js-word-spinner");
const allUsps = wordSpinner.querySelectorAll('.js-usp');
let uspIndex = 0;

// Define spin word function
function spinWords(){
    // Add .is-active class for current usp
    allUsps[uspIndex].classList.remove("is-active");

    // Set index for new usp
    uspIndex == (allUsps.length - 1) ? uspIndex = 0 : uspIndex++;

    // Add .is-active class for new usp
    allUsps[uspIndex].classList.add("is-active");
}

// Set interval to spin words
setInterval(spinWords, headerAnimationTiming);

// ***


// *** GALLERY CLICK THROUGH SLIDESHOW

function loadAllImages(gallery){
    console.log('LOADING ALL IMAGES');
    const allImages = gallery.querySelectorAll('.js-gallery-image img');

    allImages.forEach(image=>{
        image.removeAttribute('loading');
    })
}

// Get galleries
const galleries = document.querySelectorAll('.js-gallery-carousel');

if(galleries) {
    galleries.forEach(gallery => {
        let allImagesLoaded = false;
        let currentImg = 1;
        const galleryImgs = gallery?.querySelectorAll(".js-gallery-image");
        const galleryIndicator = gallery.querySelector('.js-indicator');

        // Get all thumbnails
        const thumbnailContainer = gallery.querySelector('.js-thumbnail-container');
        const thumbnails = thumbnailContainer.querySelectorAll(".js-gallery-thumbnail");

        // Add click event to change picture
        galleryImgs.forEach(galleryImg => {
            galleryImg.addEventListener('click', event => {
                // Load all gallery images after interaction
                if(!allImagesLoaded) {
                    allImagesLoaded = true;
                    loadAllImages(gallery)
                }

                // Remove lazy load for all images in carousel
                galleryImg.querySelector("img").loading = "eager";

                // Get matching thumbnails
                let oldThumb;
                let newThumb;

                thumbnails.forEach(thumbnail => {
                    if(thumbnail.dataset.imageIndex == currentImg) {
                        oldThumb = thumbnail;
                    } else if(thumbnail.dataset.imageIndex == currentImg+1) {
                        newThumb = thumbnail;
                    } else if(currentImg == galleryImgs.length) {
                        newThumb = thumbnails[0];
                    }
                });

                // Hide current image
                galleryImgs[currentImg-1].setAttribute("hidden", true);
                oldThumb.classList.remove('is-active');

                // Determine new image
                if(currentImg == galleryImgs.length) {
                    currentImg = 1;
                } else currentImg = currentImg+1;

                // Update indicator
                galleryIndicator.innerText = currentImg;

                // Display new image
                galleryImgs[currentImg-1].removeAttribute("hidden");
                newThumb.classList.add('is-active');

                const containerRect = thumbnailContainer.getBoundingClientRect();
                const activeItemRect = newThumb.getBoundingClientRect();

                if (activeItemRect.left < containerRect.left || activeItemRect.right > containerRect.right) {
                    // Calculate scroll position to center the active item
                    const scrollPosition = activeItemRect.left - containerRect.left - containerRect.width / 2 + activeItemRect.width / 2;

                    // Scroll the container
                    thumbnailContainer.scrollLeft += scrollPosition;
                }
            });
        });

        // GALLERY THUMBNAIL
        if(thumbnails) {
            thumbnails.forEach(thumbnail => {
                thumbnail.addEventListener('click', event => {
                    // Load all gallery images after interaction
                    if(!allImagesLoaded) {
                        allImagesLoaded = true;
                        loadAllImages(gallery)
                    }

                    const imgIndex = Number(thumbnail.dataset.imageIndex);

                    // Hide current image
                    const oldImg = Array.from(galleryImgs).filter(galleryImg => galleryImg.dataset.imageIndex == currentImg);
                    oldImg[0].setAttribute("hidden", true);

                    // Deactivate old thumbnail
                    const oldIndex = oldImg[0].dataset.imageIndex;
                    thumbnails[oldIndex-1].classList.remove('is-active');

                    // Keep track of current image
                    currentImg = imgIndex;

                    // Display new image
                    const newImg = Array.from(galleryImgs).filter(galleryImg => galleryImg.dataset.imageIndex == imgIndex);
                    newImg[0].removeAttribute("hidden");

                    // Activate new thumbnail
                    thumbnails[imgIndex-1].classList.add('is-active');
                });
            })
        }
    });
}

// ***


// *** CASES TOUCH INTERACTION

// todo fix this
// CHeck for touch device
// if(touchDevice){
//     carousels.forEach(carousel => {
//         // Initialize variables
//         let labelTimeout;

//         // Add touch event to change picture
//         carousel.addEventListener('touchstart', e => {
//             // Add .is-active class
//             carousel.classList.add('is-active');

//             // Clear timeout with every touch
//             clearTimeout(labelTimeout);

//             // Set timeout to remove .is-active class
//             labelTimeout = setTimeout(showLabel , labelShowTiming);

//             function showLabel(){
//                 carousel.classList.remove('is-active');
//             }
//         });
//     });
// }

// ***


// *** SHOW MORE CASES

// Initialize variables
const showMoreButton = document.querySelector(".js-show-more");

// Display button if more than 8 cases only
// carousels.length < 9 ? showMoreButton.style.display = "none" : null;

// Set class to display more cases on click
if(showMoreButton) {
    showMoreButton.addEventListener("click", e => {
        showMoreButton.classList.remove = "case-teasers__item--show-more";
        main.classList.add('show-more');
    });
}

// ***


// *** OBSERVE CASES TO AUTOLOAD SECOND IMAGE

// Set up intersection observer for cases
const observer = new IntersectionObserver((carousel) => {
    // Check if the image has entered the viewport
    if (carousel[0].isIntersecting) {
        // Get all images for case
        const caseImgs = carousel[0].target.querySelectorAll(".js-case-image");

        // Set second image in carousel to eager
        caseImgs.forEach(function (caseImg, index) {
            if(index == 1){
                caseImg.querySelector("img").loading = "eager";
            }
        });
    }
}, {rootMargin: '0px'});

// Loop through each carousel
// todo
// carousels.forEach(function (carousel) {
//     observer.observe(carousel);
// });

// ****